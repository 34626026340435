import { render, staticRenderFns } from "./LocaleSelector.vue?vue&type=template&id=1022fd4b&scoped=true"
import script from "./LocaleSelector.vue?vue&type=script&lang=js"
export * from "./LocaleSelector.vue?vue&type=script&lang=js"
import style0 from "./LocaleSelector.vue?vue&type=style&index=0&id=1022fd4b&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1022fd4b",
  null
  
)

export default component.exports