import { render, staticRenderFns } from "./SectionPanel.vue?vue&type=template&id=6f6334fe&scoped=true"
import script from "./SectionPanel.vue?vue&type=script&lang=js"
export * from "./SectionPanel.vue?vue&type=script&lang=js"
import style0 from "./SectionPanel.vue?vue&type=style&index=0&id=6f6334fe&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f6334fe",
  null
  
)

export default component.exports