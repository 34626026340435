var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.handleResize),expression:"handleResize"},{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}],staticClass:"vue-recycle-scroller",class:{
    ready: _vm.ready,
    'page-mode': _vm.pageMode,
    [`direction-${_vm.direction}`]: true,
  },on:{"&scroll":function($event){return _vm.handleScroll.apply(null, arguments)}}},[(_vm.$slots.before)?_c('div',{ref:"before",staticClass:"vue-recycle-scroller__slot"},[_vm._t("before")],2):_vm._e(),_c(_vm.listTag,{ref:"wrapper",tag:"component",staticClass:"vue-recycle-scroller__item-wrapper",class:_vm.listClass,style:({ [_vm.direction === 'vertical' ? 'minHeight' : 'minWidth']: _vm.totalSize + 'px' })},[_vm._l((_vm.pool),function(view){return _c('ItemView',_vm._g({key:view.nr.id,ref:"items",refInFor:true,staticClass:"vue-recycle-scroller__item-view",class:[
        _vm.itemClass,
        {
          hover: !_vm.skipHover && _vm.hoverKey === view.nr.key
        },
      ],style:(_vm.ready
        ? [
          (_vm.disableTransform
            ? { [_vm.direction === 'vertical' ? 'top' : 'left'] : `${view.position}px`, willChange: 'unset' }
            : { transform: `translate${_vm.direction === 'vertical' ? 'Y' : 'X'}(${view.position}px) translate${_vm.direction === 'vertical' ? 'X' : 'Y'}(${view.offset}px)` }),
          {
            width: _vm.gridItems ? `${_vm.direction === 'vertical' ? _vm.itemSecondarySize || _vm.itemSize : _vm.itemSize}px` : undefined,
            height: _vm.gridItems ? `${_vm.direction === 'horizontal' ? _vm.itemSecondarySize || _vm.itemSize : _vm.itemSize}px` : undefined,
          }
        ]
        : null),attrs:{"view":view,"item-tag":_vm.itemTag},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._t("default",null,null,props)]}}],null,true)},_vm.skipHover ? {} : {
        mouseenter: () => { _vm.hoverKey = view.nr.key },
        mouseleave: () => { _vm.hoverKey = null },
      }))}),_vm._t("empty")],2),(_vm.$slots.after)?_c('div',{ref:"after",staticClass:"vue-recycle-scroller__slot"},[_vm._t("after")],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }